.h2Skills{
     color: white;
     font-weight: 700;
     font-style: normal;
     margin-bottom: 5%;
}
.CircularProgressbar{
width: 200px;

}
.CircularProgressbar .CircularProgressbar-path{
    stroke: #07D665;
}
.CircularProgressbar .CircularProgressbar-text{
    fill: white;
    font-size: 1em;
    font-weight: 700;
    text-anchor: middle;
}
.programmingHeadline{
    color: white;
    font-weight: 700;
    margin-top: 10%;
    right: 5%;
}
.first4Skills {
    margin-bottom: 10%;
}
.second4Skills {
    margin-bottom: 10%;
}

@media screen and (max-width: 600px){
    .CircularProgressbar {
width: 100px;
margin-left: 30px;
    }
    .programmingHeadline{
        font-size: 16px;
        text-align: center;
    }
    .first4Skills {
        margin-bottom: 0;
    }
}