.h2About {
    color: white;
    font-weight: 700;
    font-style: normal;
}
.pAbout{
    color: white;
    font-weight: 600;
}
.circle{
    height: 350px;
    width: 350px;
    background-color: #07D665;
    border-radius: 50%;
    margin-bottom: 3%;
}
.myImage{
    height: 350px;
    width: 350px;
    border-radius: 50%;
    margin-left: 20%;
}
.contactinfo{
    margin-bottom: 10%;
    margin-top: 2%;
}
.aboutInfo {
color: white;
font-size: 1.2em;
font-family: roboto;
font-style: normal;
font-weight: 500;
}
.aboutIcons {
    color: #07D665;
    font-size: 3em;

}
@media screen and (max-width: 600px){
    .circle{
        height: 250px;
        width: 250px;
        margin-left: 20px;
    }
    .myImage{
        height: 250px;
        width: 250px;
    }
    .contactinfo{
        display: inline-flex;
        font-size: 10px;
        flex-wrap: wrap;
        align-content: stretch;
        justify-content: space-evenly;
        align-items: center;
    }
}